import * as Sentry from "@sentry/browser";

export function init() {
  window.addEventListener("hashchange", addUrlHash);
}

export function sentryLoaded() {
  addUrlHash();
}

export function addUrlHash() {
  Sentry.configureScope(setUrlHashTag);
}

function setUrlHashTag(scope) {
  scope.setTag(
    "urlHash",
    window.location.hash.replace(/\/[0-9]+(\/|$)/g, "/:id/")
  );
}
