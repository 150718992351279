export const blue = [
  "app-dashboard-ui",
  "calendar-ui",
  "communications-ui",
  "login-ui",
  "global-settings",
  "notifications-ui",
  "invite-team-member",
  "client-menu",
  "clients-ui",
  "data-mapping-ui",
  "dedupe-ui",
];

export const corona = [
  "end-user-forms-ui",
  "resolution-cases-menu",
  "integrations-ui",
  "logic-ui",
  "sme-navbar",
  "sme-ui",
  "sme-root-config",
  "sme-version",
  "notices-ui",
  "transcripts-ui",
  "resolution-cases-ui",
];

export const gold = ["billing-ui"];

export const green = [
  "docs-ui",
  "document-editor-ui",
  //it appears that letter-generator-ui and letters-ui are the same,
  //but sofe-inspector registers letters-ui
  "letter-generator-ui",
  "letters-ui",
  "signing-ui",
];

export const taxPrep = ["xml-download-parcel"];

export const red = [
  "notes-ui",
  "rich-text-ui",
  "tasks-ui",
  "engagements-ui",
  "tax-prep-ui",
];

export const infrastructure = [
  "app-loader-ui",
  "canopy-admin-ui",
  "canopy-styleguide",
  "canopy-urls",
  "client-portal-ui",
  "common-dependencies",
  "cp-client-auth",
  "error-logging",
  "feature-toggles",
  "fetcher",
  "mobile-root-config",
  "node-bootstrap-server",
  "primary-navbar",
  "sofe-inspector",
  "toast-service",
  "toolbox",
];
