import {
  blue,
  gold,
  taxPrep,
  corona,
  green,
  red,
  infrastructure,
} from "./ownership.js";
import { findKey, property } from "lodash";
import {
  getSingleSpaVersioningInformation,
  getFeatureToggleInformation,
} from "./single-spa.helpers.js";

export function getAssociatedService(exception, manifest) {
  let serviceName;

  try {
    const frames = property("values[0].stacktrace.frames")(exception);

    // If there is no stacktrace, assume the error is in app-loader-ui
    if (!frames || !frames.length) return "unknown";

    serviceName = getServiceFromFilename(
      frames[frames.length - 1].filename,
      manifest
    );

    if (!serviceName) {
      serviceName = errorInSmeRootConfig(frames[frames.length - 1].filename)
        ? "spasidious"
        : errorInNodeBootstrapServer(frames[frames.length - 1].filename)
        ? "node-bootstrap-server"
        : "unknown";
    }
  } catch (error) {
    console.error(error);
    serviceName = "error";
  }

  return serviceName;
}

export function getVersioningInformation(manifest, service) {
  return getSingleSpaVersioningInformation(manifest, service);
}

export function getFeatureToggleInfo() {
  return getFeatureToggleInformation();
}

export function getSquad(service) {
  if (service.endsWith("!sofe")) {
    service = service.slice(0, -5);
  }
  if (blue.includes(service)) return "blue";
  if (corona.includes(service)) return "corona";
  if (gold.includes(service)) return "gold";
  if (taxPrep.includes(service)) return "tax-prep";
  if (green.includes(service)) return "green";
  if (red.includes(service)) return "red";
  if (infrastructure.includes(service)) return "infrastructure";
  return "unknown";
}

function errorInNodeBootstrapServer(filename) {
  return (
    filename.includes("system.configured.js") ||
    filename.includes("common-dependencies")
  );
}

function errorInSmeRootConfig(filename) {
  return filename.includes("spasidious.build");
}

function getServiceFromFilename(filename, manifest) {
  return findKey(manifest, (url) =>
    filename.includes(url.substring(url.lastIndexOf("/") + 1))
  );
}
